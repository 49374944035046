import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'

let config = {
    apiKey: 'AIzaSyBgB_I-tiuHtWcNFkS0BfVDcQpggmUqlb4',
    authDomain: 'sayls-chiesi.firebaseapp.com',
    projectId: 'sayls-chiesi',
    storageBucket: 'sayls-chiesi.appspot.com',
    databaseURL: 'https://sayls-chiesi.firebaseio.com'
}

firebase.initializeApp(config)

export const GoogleProvider = new firebase.auth.GoogleAuthProvider()
export const auth = firebase.auth()
export const db = firebase.firestore()
export const st = firebase.storage()
export const fc = firebase.functions()
export default firebase