import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import sign from './firebase/sign.js'
import 'tailwindcss/tailwind.css'

sign.setOnAuthState();

createApp(App).use(store).use(router).mount('#app')
