let estados = [
    {id: null, text: '- Selecciona'},
    {id: 1, text: 'Aguascalientes'},
    {id: 2, text: 'Baja California'},
    {id: 3, text: 'Baja California Sur'},
    {id: 4, text: 'Campeche'},
    {id: 5, text: 'CDMX'},
    {id: 6, text: 'Coahuila'},
    {id: 7, text: 'Colima'},
    {id: 8, text: 'Chiapas'},
    {id: 9, text: 'Chihuahua'},
    {id: 10, text: 'Durango'},
    {id: 11, text: 'Estado de México'},
    {id: 12, text: 'Guanajuato'},
    {id: 13, text: 'Guerrero'},
    {id: 14, text: 'Hidalgo'},
    {id: 15, text: 'Jalisco'},
    {id: 16, text: 'Michoacán'},
    {id: 17, text: 'Morelos'},
    {id: 18, text: 'Nayarit'},
    {id: 19, text: 'Nuevo León'},
    {id: 20, text: 'Oaxaca'},
    {id: 21, text: 'Puebla'},
    {id: 22, text: 'Querétaro'},
    {id: 23, text: 'Quintana Roo'},
    {id: 24, text: 'San Luis Potosí'},
    {id: 25, text: 'Sinaloa'},
    {id: 26, text: 'Sonora'},
    {id: 27, text: 'Tabasco'},
    {id: 28, text: 'Tamaulipas'},
    {id: 29, text: 'Tlaxcala'},
    {id: 30, text: 'Veracruz'},
    {id: 31, text: 'Yucatán'},
    {id: 32, text: 'Zacatecas'}
];

let especialidades = [
    {id:null, text:'- Selecciona'}, 
    {id:'alergia', text:'Alergia'}, 
    {id:'geriatria', text:'Geriatría'}, 
    {id:'inmuno', text:'Inmunología Clínica'}, 
    {id:'medinterna', text:'Medicina Interna'} , 
    {id:'neumo', text:'Neumología'},
    {id:'otorrino', text:'Otorrinolaringología'},
    {id:'pediatria', text:'Pediatría'},
    {id:'otra', text:'Otra'}
];

let grados = [
    {id:null, text:'- Selecciona'}, 
    {id:'r1', text:'R1'}, 
    {id:'r2', text:'R2'}, 
    {id:'r3', text:'R3'}, 
    {id:'r4', text:'R4'}
];

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

function guid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function formatPhone(num) {
    let tempo = num.split('');
	let final = tempo[0] + tempo[1] + tempo[2] + ' ' + tempo[3] + tempo[4] + tempo[5] + ' ' + tempo[6] + tempo[7] + ' ' + tempo[8] + tempo[9];
    return final;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default { estados, especialidades, grados, guid, capitalizeFirstLetter, formatPhone, shuffle, numberWithCommas }