<template>
	<div class="h-screen flex overflow-hidden" :class="route.path=='/play' ? 'bg-black' : 'bg-blue-gray-50'" v-if="state.user">
		<TransitionRoot as="template" :show="sidebarOpen">
			<Dialog as="div" static class="fixed inset-0 flex z-40 xl:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
				<TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
				</TransitionChild>
				<TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
					<div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
						<TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
							<div class="absolute top-0 right-0 -mr-12 pt-2">
								<button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
									<span class="sr-only">Close sidebar</span>
									<XIcon class="h-6 w-6 text-white" aria-hidden="true" />
								</button>
							</div>
						</TransitionChild>
						<div class="flex-1 h-0 pt-0 pb-4 overflow-y-auto">
							<div class="flex-shrink-0 flex items-center px-0">
								<img :src="require('@/assets/core/logo.png')" alt="Chiesi México" class="w-48">
							</div>
							<div>
								<nav class="mt-5 px-2 space-y-1">
									<div v-for="item in state.navigation" :key="item.name" @click="go(item.to)" :class="[item.current ? 'bg-accent text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-primary', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer']">
										<component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-400 group-hover:text-primary', 'mr-3 flex-shrink-0 h-5 w-5']" aria-hidden="true" />
										{{ item.name }}
									</div>
								</nav>
								<template v-if="state.user.role == 'staff'">
									<div class="pt-1 mt-6 bg-gray-100"></div>
									<div class="mt-5 px-6 text-xs font-semibold text-accent">ADMIN</div>
									<nav class="mt-3 px-2 space-y-1" v-if="state.user.role == 'staff'">
										<div v-for="item in state.navStaff" :key="item.name" @click="go(item.to)" :class="[item.current ? 'bg-accent text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-primary', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer']">
											<component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-400 group-hover:text-primary', 'mr-3 flex-shrink-0 h-5 w-5']" aria-hidden="true" />
											{{ item.name }}
										</div>
									</nav>
								</template>
							</div>
						</div>
						<div class="flex-shrink-0 flex border-t border-gray-200 p-4 bg-accent">
							<div class="flex-shrink-0 w-full group block">
								<div class="flex items-center">
									<div>
										<UserCircleIcon class="inline-block text-white w-6 h-6 text-opacity-90" />
									</div>
									<div class="ml-2">
										<p class="text-sm font-medium text-white group-hover:text-white">
											{{state.user.name}} {{state.user.paterno}}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</TransitionChild>
				<div class="flex-shrink-0 w-14">
					<!-- Force sidebar to shrink to fit close icon -->
				</div>
			</Dialog>
		</TransitionRoot>

		<!-- Static sidebar for desktop -->
		<div class="hidden xl:flex xl:flex-shrink-0">
			<div class="flex flex-col w-60">
				<!-- Sidebar component, swap this element with another sidebar if you like -->
				<div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
					<div class="flex-1 flex flex-col pt-0 pb-4 overflow-y-auto">
						<div class="flex items-center flex-shrink-0 px-0">
							<img :src="require('@/assets/core/logo.png')" alt="Chiesi México" class="w-48">
						</div>
						<div>
							<nav class="mt-5 flex-1 px-2 bg-white space-y-1">
								<div v-for="item in state.navigation" :key="item.name" @click="go(item.to)" :class="[item.current ? 'bg-accent text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-primary', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer']">
									<component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-400 group-hover:text-primary', 'mr-3 flex-shrink-0 h-5 w-5']" aria-hidden="true" />
									{{ item.name }}
								</div>
							</nav>
							<template v-if="state.user.role == 'staff'">
								<div class="pt-1 mt-6 bg-gray-100"></div>
								<div class="mt-5 px-6 text-xs font-semibold text-accent">ADMIN</div>
								<nav class="mt-3 flex-1 px-2 bg-white space-y-1">
									<div v-for="item in state.navStaff" :key="item.name" @click="go(item.to)" :class="[item.current ? 'bg-accent text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-primary', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer']">
										<component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-400 group-hover:text-primary', 'mr-3 flex-shrink-0 h-5 w-5']" aria-hidden="true" />
										{{ item.name }}
									</div>
								</nav>
							</template>
						</div>
					</div>
					<div class="flex-shrink-0 flex border-t border-gray-200 p-4 bg-accent">
						<div class="flex-shrink-0 w-full group block">
							<div class="flex items-center">
								<div>
									<UserCircleIcon class="inline-block text-white w-6 h-6 text-opacity-90" />
								</div>
								<div class="ml-2">
									<p class="text-xs font-medium text-white group-hover:text-white">
										{{state.user.name}} {{state.user.paterno}}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-col w-0 flex-1 overflow-hidden">
			<div class="xl:hidden pl-1 pr-3 pt-3 flex justify-end">
				<button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent" @click="sidebarOpen = true">
					<span class="sr-only">Open sidebar</span>
					<MenuIcon class="h-10 w-10" aria-hidden="true" />
				</button>
			</div>
			<main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
				<div class="py-0">
					<div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
						<h1 class="text-2xl font-semibold text-accent" v-if="state.title!=''">{{state.title}}</h1>
					</div>
					<div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
						<!-- Replace with your content -->
						    <router-view />
						<!-- /End replace -->
					</div>
				</div>
			</main>
		</div>
	</div>

	<div class="h-screen flex items-center justify-center" v-if="state.user == null">
		<div>
			<div class="flex items-center justify-center flex-shrink-0">
				<img :src="require('@/assets/core/logo-sayls.png')" alt="Fester" class="w-36">
			</div>
			<div class="mt-8 flex justify-center">
				<Loader />
			</div>
		</div>
	</div>
</template>

<script>
import Sign from '@/firebase/sign'
import { ref, computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
	AdjustmentsIcon,
	UsersIcon,
	DocumentTextIcon,
	HomeIcon,
	MenuIcon,
	LightningBoltIcon,
	XIcon,
	UserCircleIcon,
	LogoutIcon,
	BookOpenIcon
} from '@heroicons/vue/outline'
import Loader from '@/components/core/Loader'

export default {
	components: {
		Dialog,
		DialogOverlay,
		TransitionChild,
		TransitionRoot,
		MenuIcon,
		XIcon,
		UserCircleIcon,
		Loader
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const sidebarOpen = ref(false);

		const user = computed(() => store.getters.user);

		const state = reactive({
			navigation: [
				{ name: 'Inicio', to: '/', icon: HomeIcon, current: true, title:'' },
				{ name: 'Jugar', to: '/play', icon: LightningBoltIcon, current: false, title:''  },
				{ name: 'Instrucciones', to: '/instructions', icon: BookOpenIcon, current: false, title:''  },
				{ name: 'Caso clínico', to: '/case', icon: DocumentTextIcon, current: false, title:''  },
				{ name: 'Mis puntos', to: '/points', icon: AdjustmentsIcon, current: false, title:''  },
				{ name: 'Salir', to: 'out', icon: LogoutIcon, current: false, title:'Adiós'  }
			],
			navStaff: [
				{ name: 'Casos', to: '/cases', icon: DocumentTextIcon, current: false, title:'' },
				{ name: 'Usuarios', to: '/users', icon: UsersIcon, current: false, title:'' }
			],
			title: '',
			user: user
		});
		
		function signout() {
			Sign.signout()
				.then(response => {
					if (response === true) {
						store.dispatch('setNotification', {type: true, title: 'Hasta luego', content: 'Le esperamos pronto.'});
					} else {
						store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión.'});
					}
				});
		}

		function go(to) {
			if (to == 'out') {
				signout();
				return;
			}
			router.push(to);

			if (to == '/account') {
				state.title = 'Mi cuenta';
				return;
			}
		}

		watch(route, (current, old) => {
			checkCurrent();
		});

		watch(user, (current, old) => {
			checkCurrent();
		});

		function checkCurrent() {
			if (user && user.value) {
				if (user.value.role == 'user') {
					state.navigation.forEach(dt => {
						dt.current = false;
					});
				}

				if (user.value.role == 'staff') {
					state.navigation.forEach(dt => {
						dt.current = false;
					});

					state.navStaff.forEach(dt => {
						dt.current = false;
					});
				}

				let destiny;
				if (user.value.role == 'user') {
					destiny = state.navigation.find(item => {return item.to == route.path});
				}
				if (user.value.role == 'staff') {
					destiny = state.navigation.find(item => {return item.to == route.path});
					if (destiny == undefined) {
						destiny = state.navStaff.find(item => {return item.to == route.path});
					}
				}

				if (destiny) {
					destiny.current = true;
					
					if (route.path != '/account') {
						state.title = destiny.title;
					}
				}

				sidebarOpen.value = false
			}
		}

		return {
			signout,
		    sidebarOpen,
			user,
			go,
			state,
			route
		}
	}
}
</script>
