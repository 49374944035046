import firebase, {db, auth, fc} from './connection'
import store from '../store.js'
import router from '../router.js'
import Misc from '@/services/misc'

function redirectorSign(user) {
    let path = router.currentRoute.value.path;

    if (path == '/terminos-y-condiciones' || path == '/aviso-de-privacidad') {
        return;
    }

    if (user) {
        if (user.name) {
            if (user.pending) {
                router.push('/pending');
            } else {
                if (path == '/signin') {
                    router.push('/');
                }
            }
        } else {
            router.push('/complete-info');
        }
    } else if (path != '/signup' && path != '/recover-password') {
       router.push('/signin');
    }
}

function getUser(uid) {
    let ref = db.collection('users').doc(uid);

    ref.get()
        .then(doc => {
                if (doc.exists) {
                    let user = doc.data();
                    user.uid = doc.id;
                    store.dispatch('setUser', user);
                    redirectorSign(user);
                }
        });
}

export default {
    setOnAuthState() {
        auth.onAuthStateChanged(user => {
            if (user) {
                getUser(user.uid);
            } else {
                redirectorSign(null);
            }
        });
    },

    isLoggedIn() {
		return auth.currentUser;
    },

    reInitUser(uid) {
        getUser(uid);
    },
    
    signin(payload) {
        return auth.signInWithEmailAndPassword(payload.email, payload.pwd)
				.then( response => {
                    getUser(response.user.uid);
					return true;
				})
				.catch(error => {
					return error;
				});
    },

    signup(payload) {
        return auth.createUserWithEmailAndPassword(payload.email, payload.pwd)
                    .then(response => {
                        let ref = db.collection('users').doc(response.user.uid);
                        return ref.set({
                                    email: response.user.email,
                                    createdAt: firebase.firestore.FieldValue.serverTimestamp(), 
                                    role: 'user',
                                    pending: true,
                                    gamePoints: 0,
                                    casePoints: 0,
                                    timePlayed: 0,
                                    questionOrder: Misc.shuffle([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]),
                                    answers: null,
                                    case: null
                                })
                                .then(() => {
                                    getUser(response.user.uid);
                                    return true;
                                })
                                .catch(error => {
                                    return error;
                                });
                    })
                    .catch(error => {
                        return error;
                    });
    },

    signout() {
        return auth.signOut()
				.then( () => { 
                    return true;
				})
				.catch(error => {
					return error;
				});
    },

    recoverPassword(email) {
		return auth.sendPasswordResetEmail(email)
				.then( () => {
					return true;
				})
				.catch(error => {
					return error;
				});
	}
}