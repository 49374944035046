<template>
	<Clean v-if="state.show == 'clean'" />
	<Intern v-if="state.show == 'intern'" />
	<Notifications />
</template>

<script>
import Notifications from '@/components/core/Notifications'
import Clean from '@/components/core/layouts/Clean'
import Intern from '@/components/core/layouts/Intern'
import { reactive, watch, computed } from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { useStore } from 'vuex'

export default {
	components: {
   		Notifications,
		Clean,
		Intern
  	},
	setup() {
		const route = useRoute();
		const router = useRouter();

		const store = useStore();
		const user = computed(() => store.getters.user);

		const state = reactive({
			show: null,
			user: user
		});

		watch(route, (current, old) => {
			state.show = route.meta.layout;

			if (state.user) {
				if (route.meta.authorized) {
					if (route.meta.authorized != state.user.role) {
						router.push('/');
					}
				}
			}
		});

		return {
			state
		}
	}
}
</script>
