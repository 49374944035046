<template>
<div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
	<div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-200" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <div v-if="state.show" class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden" :class="state.type ? 'bg-green-500' : 'bg-red-500'">
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            <CheckCircleIcon class="h-6 w-6 text-green-50" aria-hidden="true" v-if="state.type == true" />
                            <InformationCircleIcon class="h-6 w-6 text-red-50" aria-hidden="true" v-if="state.type == false" />
                        </div>

                        <!-- POSITIVE -->
                        <div class="ml-3 w-0 flex-1 pt-0.5" v-if="state.type == true">
                            <p class="text-sm font-medium text-green-50" v-if="state.title != ''">
                                {{state.title}}
                            </p>
                            <p class="mt-1 text-sm text-green-50 text-opacity-80" v-if="state.content != ''">
                                {{state.content}}
                            </p>
                        </div>

                        <!-- NEGATIVE -->
                        <div class="ml-3 w-0 flex-1 pt-0.5" v-if="state.type == false">
                            <p class="text-sm font-medium text-red-50">
                                Error
                            </p>
                            <p class="mt-1 text-sm text-red-50 text-opacity-80" v-if="state.content != ''">
                                {{state.content}}
                            </p>
                        </div>

                        <div class="ml-4 flex-shrink-0 flex">
                            <button @click="state.show = false" class="rounded-md inline-flex text-opacity-80 hover:text-opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white" :class="state.type ? 'text-green-50' : 'text-red-50'">
                                <span class="sr-only">Close</span>
                                <XIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</div>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { CheckCircleIcon } from '@heroicons/vue/outline'
import { InformationCircleIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'

export default {
	components: {
   	    CheckCircleIcon,
        InformationCircleIcon,
    	XIcon,
  	},
	setup() {
        const store = useStore();

        const state = reactive({
			show: false,
            type: null,
            title: '',
            content: ''
		});

        const notify = computed(() => store.getters.notification);

        watch(notify, (current, old) => {
            if (current.type != null) {
                setNotify(current);
            }
        });

        function setNotify(current) {
            state.type = current.type;
            
            if (current.title) {
                state.title = current.title;
            }
            
            if (current.content) {
                state.content = current.content;
            }

            state.show = true;
            reset();
        }

        function reset() {
            store.dispatch('setNotification', {type: null});
            let velocity = state.type ? 3000 : 7000;

            setTimeout(() => {
                    state.show = false;
                    state.type = null;
                    state.title = '';
                    state.content = '';
                }, velocity);
        }

		
		
		return {
			state
		}
	}
}
</script>
