<template>
    <div class="h-screen" :class="route.path=='/play' ? 'bg-black' : 'bg-blue-gray-50'">
        <router-view />
    </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
    setup() {
        const route = useRoute();

        return {
            route
        }
    },
}
</script>