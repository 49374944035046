import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	// PUBLIC - - - - - - -
	{
		path: '/signin',
		name: 'Signin',
		component: () => import('@/views/public/Signin.vue'),
		meta: {
			layout: 'clean'
		}
	},
	{
		path: '/signup',
		name: 'SignUp',
		component: () => import('@/views/public/Signup.vue'),
		meta: {
			layout: 'clean'
		}
	},
	{
		path: '/recover-password',
		name: 'RecoverPassword',
		component: () => import('@/views/public/RecoverPassword.vue'),
		meta: {
			layout: 'clean'
		}
	},
	{
		path: '/terminos-y-condiciones',
		name: 'TerminosCondiciones',
		component: () => import('@/views/public/TerminosCondiciones.vue'),
		meta: {
			layout: 'clean'
		}
	},
	{
		path: '/aviso-de-privacidad',
		name: 'AvisoPrivacidad',
		component: () => import('@/views/public/AvisoPrivacidad.vue'),
		meta: {
			layout: 'clean'
		}
	},
	// GROUND - - - - - - -
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/ground/Home.vue'),
		meta: {
			layout: 'intern'
		}
	},
	{
		path: '/play',
		name: 'Play',
		component: () => import('@/views/ground/Play.vue'),
		meta: {
			layout: 'intern'
		}
	},
	{
		path: '/case',
		name: 'ClinicCase',
		component: () => import('@/views/ground/ClinicCase.vue'),
		meta: {
			layout: 'intern'
		}
	},
	{
		path: '/points',
		name: 'Points',
		component: () => import('@/views/ground/Points.vue'),
		meta: {
			layout: 'intern'
		}
	},
	{
		path: '/instructions',
		name: 'Instructions',
		component: () => import('@/views/ground/Instructions.vue'),
		meta: {
			layout: 'intern'
		}
	},
	{
		path: '/users',
		name: 'Users',
		component: () => import('@/views/admin/Users.vue'),
		meta: {
			layout: 'intern',
			authorized: 'staff'
		}
	},
	{
		path: '/cases',
		name: 'Cases',
		component: () => import('@/views/admin/Cases.vue'),
		meta: {
			layout: 'intern',
			authorized: 'staff'
		}
	},
	{
		path: '/complete-info',
		name: 'CompleteInfo',
		component: () => import('@/views/ground/CompleteInfo.vue'),
		meta: {
			layout: 'clean'
		}
	},
	{
		path: '/pending',
		name: 'Pending',
		component: () => import('@/views/ground/Pending.vue'),
		meta: {
			layout: 'clean'
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
