import { createStore } from 'vuex'

export default createStore({
	state: {
		user: null,
		notification: {
			type: null
		},
		fileUploading: false,
		fileUploaded: false,
		fileProgress: 0,
		timer: 0
	},
	getters: {
		user: state => state.user,
		notification: state => state.notification,
		fileUploading: state => state.fileUploading,
		fileUploaded: state => state.fileUploaded,
		fileProgress: state => state.fileProgress,
		timer: state => state.timer,
	},
	mutations: {
		set_user(state, user) {
			state.user = user;
		},
		set_notification(state, payload) {
			state.notification = payload;
		},
		set_fileUploading(state, payload) {
			state.fileUploading = payload;
		},
		set_fileUploaded(state, payload) {
			state.fileUploaded = payload;
			state.fileProgress = 0;
		},
		set_fileProgress(state, payload) {
			state.fileProgress = payload;
		},
		set_timer(state, payload) {
			state.timer = payload;
		},
		set_answersUser(state, payload) {
			if (state.user.answers == null) {
				state.user.answers = {}
			}

			payload.forEach(dt => {
				state.user.answers[dt] = {
					points: 0,
					responded: false
				}
			})
		}
	},
	actions: {
		setUser(ctx, user) {
			ctx.commit('set_user', user);
		},
		setNotification(ctx, payload) {
			ctx.commit('set_notification', payload);
		},
		setFileUploading(ctx, payload) {
			ctx.commit('set_fileUploading', payload);
		},
		setFileUploaded(ctx, payload) {
			ctx.commit('set_fileUploaded', payload);
		},
		setFileProgress(ctx, payload) {
			ctx.commit('set_fileProgress', payload);
		},
		setTimer(ctx, payload) {
			ctx.commit('set_timer', payload);
		},
		setAnswersUser(ctx, payload) {
			ctx.commit('set_answersUser', payload);
		}
	},
	modules: {
	}
})
